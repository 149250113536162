<app-header [transparent]="true"></app-header>
<div class="content" [class.desktop]="isDesktop">
  <div *ngIf="isDesktop" [class.desktop-sidebar]="isDesktop">
    <app-sidebar [showFilter]="true" [title]="i18n.string('notifications') + ' [' + (NumEquipmentNotifications + NumEdgeDeviceNotifications) + ']'"
      [titleicon]="'bell'" [notificationsFilter]="true">
      <app-tab-bar [fullWidth]="true" [opaque]="true" [icon]="true" (tabChanged)="onNotificationsTabBarChanged($event)"
        [disabled]="false" *ngIf="isDesktop">
        <app-tab-bar-item class="one-of-two" [inactive]="false" svg_icon="assets/icons/repair_equipment.svg">
          {{i18n.string('equipment_short')}} ({{NumEquipmentNotifications}})
        </app-tab-bar-item>
        <app-tab-bar-item class="one-of-two" [inactive]="false" svg_icon="assets/icons/edge_device.svg">
          {{i18n.string('edge_device_short')}} ({{NumEdgeDeviceNotifications}})
        </app-tab-bar-item>
      </app-tab-bar>
    </app-sidebar>
  </div>
  <div [class.desktop-main]="isDesktop">
    <ng-container *ngIf="!isDesktop">
      <h2>
        {{i18n.string('notifications')}} [{{NumEquipmentNotifications + NumEdgeDeviceNotifications}}]
      </h2>
      <app-search-filter (FilterChanged)="onFilterChanged($event)" [Input]="notifications" [filter]="filter"
        [notificationsFilter]="true">
      </app-search-filter>
    </ng-container>
    <div *ngIf="!showEdgeDeviceNotifications" >
      <div *ngIf="filtered && NumEquipmentNotifications > 1" class="found-wrapper">
        <h3>{{NumEquipmentNotifications}} {{i18n.string('notifications_found')}}</h3>
      </div>
      <div *ngIf="filtered && NumEquipmentNotifications === 1" class="found-wrapper">
        <h3>1 {{i18n.string('notification_found')}}</h3>
      </div>
      <div *ngIf="NumEquipmentNotifications === 0" class="found-wrapper">
        <h3 *ngIf="filtered">{{i18n.string('no_notifications_found')}}</h3>
        <h5 *ngIf="filtered" class="marginTop24">{{i18n.string('no_equipments_found2')}}</h5>
      </div>
    </div>
    <div *ngIf="showEdgeDeviceNotifications">
      <div *ngIf="filtered && NumEdgeDeviceNotifications > 1" class="found-wrapper">
        <h3>{{NumEdgeDeviceNotifications}} {{i18n.string('notifications_found')}}</h3>
      </div>
      <div *ngIf="filtered && NumEdgeDeviceNotifications === 1" class="found-wrapper">
        <h3>1 {{i18n.string('notification_found')}}</h3>
      </div>
      <div *ngIf="NumEdgeDeviceNotifications === 0" class="found-wrapper">
        <h3>{{i18n.string('no_notifications_found')}}</h3>
        <h5 *ngIf="filtered" class="marginTop24">{{i18n.string('no_equipments_found2')}}</h5>
      </div>
    </div>
    <app-spinner *ngIf="loading" [black]="true"></app-spinner>
    <app-tab-bar [fullWidth]="true" [opaque]="true" [icon]="true" (tabChanged)="onNotificationsTabBarChanged($event)"
      [disabled]="false" *ngIf="!isDesktop">
      <app-tab-bar-item class="one-of-two" [inactive]="false" svg_icon="assets/icons/repair_equipment.svg">
        {{i18n.string('equipment_short')}} ({{NumEquipmentNotifications}})
      </app-tab-bar-item>
      <app-tab-bar-item class="one-of-two" [inactive]="false" svg_icon="assets/icons/edge_device.svg">
        {{i18n.string('edge_device_short')}} ({{NumEdgeDeviceNotifications}})
      </app-tab-bar-item>
    </app-tab-bar>
    <ng-container *ngFor="let notification of Notifications">
      <app-notification [dateFormat]="dateFormat" [notification]="notification" [filtered]="filtered">
      </app-notification>
    </ng-container>
  </div>
</div>