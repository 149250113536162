<div *ngIf="documentationAdded" class="ga-documentation-note body-bold">
  <i nz-icon nzType="icons:check-circle-filled-transparent" class="svg-icon"></i>
  <span>{{i18n.string('add_documentation_success')}}</span>
</div>
<div class="notification-alert" [class.notificationlist]="notificationList" [class.singleAsset]="!notificationList"
  [class.redBG]="Critical && !InActive" [class.greyBG]="InActive" [class.yellowBG]="Warning" [class.falseAlert]="falseAlert">
  <div *ngIf="notification" class="notification-alert-content" (click)="onAssetName()" 
    [class.mobile]="isMobile" [class.tablet]="isTablet" [class.desktop]="isDesktop">
    <h2 class="alerttitle">{{i18n.parse(notification.measurementTitle)}}</h2>
    <div class="alert-content">
      <i nz-icon nzType="icons:cross-circle" class="svg-icon fillwhite" *ngIf="InActive"></i>
      <i nz-icon nzType="icons:status-exclamation-triangle" class="svg-icon fillwhite" *ngIf="Critical && !InActive"></i>
      <i nz-icon nzType="icons:status-exclamation-octagon" class="svg-icon" *ngIf="Warning"></i>
      <span class="alert-content-message">{{Message}} {{Instructions}}</span>
      <div class="clickable-arrow" *ngIf="!isRotate">
        <i nz-icon nzType="icons:right_click_arrow" class="svg-icon" [ngClass]="(InActive || Critical) ? 'fillwhite' : ''"></i>
      </div>
    </div>

    <div class="alert-content">    
      <div class="alert-values">
        <span *ngIf="notificationList && InActive">{{ i18n.string('in_active_notification_msg') }} {{notification.timestamp | date:'medium'}}</span>
        <span *ngIf="notificationList && notification.value !== null && !InActive && !Warning && !Critical">
          {{ i18n.string('measured_value') }}
          <span class="nowrap">
            {{ Value }}
          </span>
        </span>
        <span *ngIf="notification.value !== null && notification.prevValue !== null && !InActive && !Warning && !Critical">
          {{ i18n.string('increased_value') }}
          <span class="nowrap">
            {{ ValueIncrease }}
          </span>
        </span>
      </div>
    </div>
  </div>
  <!--
  <div *ngIf="!notificationList && falseAlert">
    <h2>Maybe there is a problem with the sensor</h2>
    <div class="falseAlert-message">
      <i nz-icon nzType="icons:instruction" class="svg-icon" [class.fillwhite]="Critical" *ngIf=""></i>
      <span>You need to reinstall manually the sensor, but if you have any problem you can contact a support
        manager.</span>
    </div>
    <div class="contact-outer-wrapper-flex">
      <img src="" class="employee-img">
      <div class="contact-wrapper-flex">
        <h4>Helen Smith</h4>
        <div class="contact-flex body-bold">
          <i nz-icon nzType="icons:mail" class="svg-icon" [class.fillwhite]="Critical"></i>
          <span>support@henkel.com</span>
        </div>
        <div class="contact-flex body-bold">
          <i nz-icon nzType="icons:phone" class="svg-icon" [class.fillwhite]="Critical"></i>
          <span>+42 052 8765 987</span>
        </div>
      </div>
    </div>
    -->
  <div *ngIf="!notificationList" class="buttonwrapperwrapper">
    <div *ngIf="!InActive && !Warning && !Critical" class="buttonwrapper">
      <app-nav-button svg_icon="icons:instruction" [darksecondary]="!Critical" (click)="onRepairAndReplace()">
        {{i18n.string('repair_and_replace')}}</app-nav-button>
    </div>
    <div class="buttonwrapper" *ngIf="notification.equipmentType !== 'Rotating Equipment'">
      <app-nav-button  [primary]="true" svg_icon="icons:documentation" (click)="overlayDocumentation = true">
        {{i18n.string('add_documentation')}}</app-nav-button>
    </div>
  </div>
  <div class="timestampwrapper">
    <span class="timestamp">Detected on: {{notification.timestamp | date: DateFormat}}</span>
  </div>  
  <app-docu-wizard *ngIf="overlayDocumentation" [assetId]="notification.objectID" [sensorId]="notification.sensorID"
    (close)="overlayDocumentation = false" (saved)="onDocumentationAdded($event)"></app-docu-wizard>
  <app-sensor-instruction *ngIf="overlayInstructions" (close)="overlayInstructions = false"></app-sensor-instruction>
</div>