import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { I18nService } from '../../../../../services/i18n.service';
import { UiService } from '../../../../../services/ui.service';

@Component({
  selector: 'app-email-toggle',
  templateUrl: './email-toggle.component.html',
  styleUrls: ['./email-toggle.component.scss'],
})
export class EmailToggleComponent implements OnInit {
  @Input() public settings: any;
  @Input() public userEmail: string = '';
  @Input() public loading: boolean = false;
  @Input() public allNotifications: boolean = false;
  @Input() public value: boolean = false;
  @Output() public valueChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(public ui: UiService, public i18n: I18nService) {}

  ngOnInit() {}

  toggleValue() {
    this.value = !this.value;
    this.valueChange.emit(this.value);
  }
}
