import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../../../cc-framework/services/authentication.service';
import { Country } from '../../model/object';
import { AssetsService } from '../../services/assets.service';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';
import { PasswordValidators } from './../../util/password-validators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  isLoading = false;
  emailAlreadyRegistered = false;
  error = false;
  showAlert: boolean = false;
  optional: boolean = true;
  checkedTerms: boolean = false;
  checkedServiceConditions: boolean = false;
  checkedDataProtection: boolean = false;
  country: string;
  public countryArray: Country[] = [];
  public countrySelected = null;

  @ViewChild('emailInputRef') emailInputRef: ElementRef;
 
  signupForm = new FormGroup(
    {
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      surname: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      designation: new FormControl(null, []),
      email: new FormControl(null, [Validators.email, Validators.required]),
      customerentity: new FormControl(null, []),
      country: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
      ]),
      choosepswd: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          PasswordValidators.patternValidator(new RegExp('(?=.*[0-9])'), {
            requiresDigit: true,
          }),
          PasswordValidators.patternValidator(new RegExp('(?=.*[A-Z])'), {
            requiresUppercase: true,
          }),
          PasswordValidators.patternValidator(new RegExp('(?=.*[a-z])'), {
            requiresLowercase: true,
          }),
          PasswordValidators.patternValidator(
            new RegExp('(?=.*[!#§$%&*+,-./:;<=>?@[^_`{|}~()\'"\\]])'),
            {
              requiresSpecialChars: true,
            }
          ),
          PasswordValidators.whiteSpaceValidator(
            new RegExp('\\s'),
            {
              requiresNoWhiteSpace: true,
            }
          ),
        ])
      ),
      repeatpswd: new FormControl(null, [Validators.required]),
    },
    {
      validators: PasswordValidators.MatchValidator,
    }
  );

  submitted: boolean = false;
  isWorking: boolean = false;

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get I18N(): I18nService {
    return this.i18n;
  }

  public passwordVisible = false;
  public showNotUsPage = true;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private ui: UiService,
    public i18n: I18nService,
    private route: ActivatedRoute,
    private assetsService: AssetsService,
  ) {}

  ngOnInit() {
   this.getCountryList();
   this.route.queryParams.subscribe(params => {
    this.country=params['country'];
  });
  if(this.country === 'us'){
    // this.countrySelected=1;
    this.showNotUsPage = false;
  }else {
    this.showNotUsPage = true;
  }
  }

  signup() {
    if (!this.signupForm.valid) return;
    this.error = false;
    this.emailAlreadyRegistered = false;
    this.isLoading = true;
    this.authService
      .signup(this.signupForm.getRawValue(), this.i18n.Lng)
      .subscribe(
        () => {
          this.isLoading = false;
          this.router.navigate(['login', 'signed-up']);
        },
        (error) => {
          this.isLoading = false;
          if (error.status === 409) {
            this.emailAlreadyRegistered = true;
            //setTimeout(() => this.emailInputRef.nativeElement.focus(), 100);
          } else {
            this.error = true;
          }
        }
      );
  }

  getCountryList() {
    this.assetsService.getCountryList().subscribe((res:Country[]) => {
      if(this.country === 'us'){
        this.showNotUsPage = false;
        this.countryArray = res.filter((item) => {
          return (JSON.parse(item.name as any).en == 'Canada' || JSON.parse(item.name as any).en == 'United States') ? true : false;
        });        
      }
      else{
        this.showNotUsPage = true;
        this.countryArray = res.filter((item) => {
          return (JSON.parse(item.name as any).en == 'Canada' || JSON.parse(item.name as any).en == 'United States') ? false : true;
        });
      }
     this.sortCountries();
    });
  }

  sortCountries() {
    this.countryArray = this.countryArray.sort((a, b) => {
      if (
        this.i18n.parse(a.name).toUpperCase() >
        this.i18n.parse(b.name).toUpperCase()
      )
        return 1;
      if (
        this.i18n.parse(a.name).toUpperCase() <
        this.i18n.parse(b.name).toUpperCase()
      )
        return -1;
      return 0;
    });
  }

  getName(value) {
    return this.i18n.parse(JSON.parse(value));
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  get f() {
    return this.signupForm.controls;
  }

  get requiresNoWhiteSpaceValid() {
    return !this.signupForm.controls['choosepswd'].hasError(
      'requiresNoWhiteSpace'
    );
  }

  get passwordValid() {
    return this.signupForm.controls['choosepswd'].errors === null;
  }

  get requiredValid() {
    return !this.signupForm.controls['choosepswd'].hasError('required');
  }

  get minLengthValid() {
    return !this.signupForm.controls['choosepswd'].hasError('minlength');
  }

  get requiresDigitValid() {
    return !this.signupForm.controls['choosepswd'].hasError('requiresDigit');
  }

  get requiresUppercaseValid() {
    return !this.signupForm.controls['choosepswd'].hasError(
      'requiresUppercase'
    );
  }

  get requiresLowercaseValid() {
    return !this.signupForm.controls['choosepswd'].hasError(
      'requiresLowercase'
    );
  }

  get requiresSpecialCharsValid() {
    return !this.signupForm.controls['choosepswd'].hasError(
      'requiresSpecialChars'
    );
  }

  resetEmailAlreadyRegisterdcheck() {
    this.emailAlreadyRegistered = false;
  }

  toTermsOfUse() {
    this.country === 'us' ? this.router.navigate(['us/terms-of-use']) : this.router.navigate(['/terms-of-use']);
  }
  toDataProtectionDeclaration() {
    this.country === 'us' ? this.router.navigate(['us/data-protection']) : this.router.navigate(['/data-protection']);
  }
  toServiceConditions() {
    this.router.navigate(['/service-conditions']);
  }
}
