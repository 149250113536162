<!-- banner Notification -->
<div *ngIf="showBannerNotification" class="banner-notification-hint">
  <div class="inner-banner-notification">
    <div class="inner-message">
      <i
        nz-icon
        nzType="icons:exclamation-mark-filled"
        class="svg-icon fillwhite"
      >
      </i>
      {{ bannerNotificationTitle }}
    </div>
    <div class="exit-icon">
      <button (click)="closeBannerNotification()">
        <i nz-icon [nzType]="'icons:exit-white'" class="svg-icon fillwhite"></i>
      </button>
    </div>
  </div>
  <div class="banner-notification-message">
    <p>
      {{ bannerNotificationMessage }}
    </p>
    <button (click)="closeBannerNotification()" class="underline">
      {{ i18n.string("understood") }}
    </button>
  </div>
</div>
