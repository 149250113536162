import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AssetDetail } from '../../../../model/asset';
import { HistoryItem } from '../../../../model/history';
import { Location } from '../../../../model/location';
import { Sensor } from '../../../../model/sensor';
import { DateFormatSettings } from '../../../../model/settings';
import { AssetsService } from '../../../../services/assets.service';
import { I18nService } from '../../../../services/i18n.service';
import { SettingsService } from '../../../../services/settings.service';
import { UiService } from '../../../../services/ui.service';

@Component({
  selector: 'app-asset-sensor-history',
  templateUrl: './asset-sensor-history.component.html',
  styleUrls: ['./asset-sensor-history.component.scss'],
})
export class AssetSensorHistoryComponent implements OnInit, AfterViewInit {
  public asset: AssetDetail = null;
  public sensor: Sensor = null;
  public loading: boolean = false;
  public overlayDocumentation: boolean = false;
  public thumbnailLoading;
  public thumbnailError;
  public breadcrumbsLoading;
  public historyItems: HistoryItem[] = [];
  public documentationAdded = false;
  public assetID: number = null;
  public sensorID: number = null;
  public measurementID: number = null;
  public dateFormat: DateFormatSettings;

  public pageSize = 10;
  public pageIndex = 1;

  get HistoryItems(): HistoryItem[] {
    return this.historyItems.slice(
      this.pageSize * (this.pageIndex - 1),
      this.pageSize * this.pageIndex
    );
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  get isRotating(): boolean {
    return !!this.asset
      ? this.asset.equipmentType === 'Rotating Equipment'
      : false;
  }
  
  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    public router: Router,
    public ui: UiService,
    public i18n: I18nService,
    public settings: SettingsService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((result: any) => {
      this.assetID = +result.params['id'];
      this.sensorID = +result.params['sensorid'];
      this.measurementID = +result.params['measurementid'];
      this.refresh();
    });
    
    this.ui.onNavBack(() => {
      this.router.navigate(['asset', this.assetID, this.sensorID], {
        replaceUrl: true,
      });
    });
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  refresh(disableCache = false) {
    this.loading = true;
    this.documentationAdded = false;
    // TODO: write dedicated function to only load single measurement history data
    this.settings.getUserSettings().subscribe((settings) => {
      this.dateFormat = settings.DateFormat;

      /* this.assetsService
        .getAsset(this.assetID, disableCache, settings.MeasurementSystem)
        .subscribe(
          (result: {
            asset: AssetDetail;
            thumbnailLoading: boolean;
            breadcrumbsLoading: boolean;
          }) => {
            this.loading = false;
            this.thumbnailLoading = result.thumbnailLoading;
            this.breadcrumbsLoading = result.breadcrumbsLoading;
            this.asset = result.asset;
            this.ui.setBackText(this.i18n.parse(this.asset.objectName));
            this.asset.sensors = this.asset.sensors
              .filter((sensor) => sensor.sensorID === this.sensorID)
              .map((sensor) => {
                sensor.telemetry = sensor.telemetry.filter(
                  (telemetry) =>
                    telemetry.measurementTypeID === this.measurementID ||
                    this.measurementID < 0
                );
                return sensor;
              });

            if (this.asset.sensors.length) {
              this.sensor = this.asset.sensors[0];
            }
          }
        ); */
      this.assetsService
        .getAsset(this.assetID, disableCache, settings.MeasurementSystem)
        .subscribe(
          (result: {
            asset: AssetDetail;
            plant: Location;
            thumbnailLoading: boolean;
            breadcrumbsLoading: boolean;
          }) => {
            this.loading = false;
            this.breadcrumbsLoading = result.breadcrumbsLoading;
            this.asset = result.asset;
            const sensor = this.asset.sensors.find(
              (_sensor) => _sensor.sensorID === this.sensorID
            );

            this.sensor = sensor;

            this.ui.setBackText(
              this.i18n.parse(this.asset.objectName) +
                (!!sensor
                  ? ' | ' + this.i18n.parse(sensor.sensorDisplayLabel)
                  : '')
            );

            if (!!this.asset['picture'] && this.thumbnailLoading === null) {
              // check thumbnailLoading with null to avoid setting thumbnailLoading to true twice (happens on initial page load)
              this.thumbnailLoading = true;
              this.thumbnailError = false;
            }
          },
          (error) => {
            this.loading = false;
          }
        );
      this.assetsService
        .getObjectHistory(this.assetID, this.sensorID)
        .subscribe((result: HistoryItem[]) => {
          this.historyItems = result.filter(
            (item) =>
              !this.sensorID ||
              this.sensorID < 0 ||
              item.sensorID === this.sensorID ||
              !item.sensorID ||
              item.sensorID < 0
          );
        });
    });
  }

  onDocumentationAdded() {
    this.refresh();
    this.documentationAdded = true;
  }
}
