import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserSettings } from '../../../model/settings';
import { I18nService } from '../../../services/i18n.service';
import { UiService } from '../../../services/ui.service';
import { toObservableObject } from '../../../util/observable-object';
import { SettingsService } from './../../../services/settings.service';

@Component({
  selector: 'app-settings-subpage',
  templateUrl: './settings-subpage.component.html',
  styleUrls: ['./settings-subpage.component.scss'],
})
export class SettingsSubpageComponent implements OnInit, AfterViewInit {
  public url: string = '';
  public test: boolean = false;
  public faqQuestions: boolean[] = [false, false, false];
  public settings: any;
  public settingsCopyForProfile: UserSettings; // this is used so we can make changes to the profile without commiting them immediately
  public changes: boolean = false;
  public loading: boolean = false;
  public success: boolean = false;
  public error: boolean = false;

  private firstParameter: any;

  /* = {
    language: 'en',
    measurement_system: 'mt',
    date_format: 'dd-mm-yyyy',
    theme: 'light',
    notifications: {
      sound: false,
      vibration: false,
    },
  };*/

  profile: { username?; language? } = {};

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public ui: UiService,
    private settingsService: SettingsService,
    public i18n: I18nService
  ) {}

  ngOnInit() {
    this.refresh();
    this.ui.onNavBack(null);
    this.ui.setBackText(this.i18n.string('settings'));
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  refresh() {
    const urlParts = this.router.url.replace('/settings/', '').split('/');
    this.url = urlParts[0];
    this.firstParameter = urlParts.length > 1 ? urlParts[1] : '';

    this.loading = true;
    this.settingsService
      .getUserSettings()
      .subscribe((settings: UserSettings) => {
        this.settingsCopyForProfile = Object.assign(new UserSettings(), {
          ...settings,
        });
        this.settings = toObservableObject(settings);
        this.settings.observation$.subscribe((modification) => {
          this.success = false;
          this.error = false;
          if (modification.oldValue === modification.newValue) return;
          this.settingsService.setUserSettings(this.settings).subscribe(
            () => {
              this.success = true;
              this.ui.setBackText(this.i18n.string('settings'));
            },
            () => (this.error = true)
          );
        });
        this.loading = false;
      });
  }

  getTitle() {
    switch (this.url) {
      case 'profile':
        return this.i18n.string('profile');
      case 'change-password':
        return this.i18n.string('change_password');
      case 'language':
        return this.i18n.string('language');
      case 'metric':
        return this.i18n.string('measurement_system');
      case 'appearance':
        return this.i18n.string('appearance');
      case 'notifications':
        return this.i18n.string('notifications' + this.firstParameter);
      case 'technical_documents':
        return this.firstParameter === 'flange'
          ? this.i18n.string('technical_documents_smart_flange')
          : this.firstParameter === 'steamtrap'
          ? this.i18n.string('technical_documents_steamtrap')
          : this.firstParameter === 'rotatingequipment'
          ? this.i18n.string('technical_documents_rotatingequipment')
          : this.i18n.string('technical_documents');
      case 'help':
        return this.i18n.string('help_and_support');
      case 'help/about':
        return this.i18n.string('about_loctite');
      case 'help/faq':
        return this.i18n.string('faq');
      case 'terms':
        return this.i18n.string('imprint');
      case 'terms/termsofservice':
        return this.i18n.string('terms_of_service');
      case 'terms/privacypolicy':
        return this.i18n.string('privacy_policy');
      case 'imprint':
        return this.i18n.string('imprint2');
      case 'terms-of-use':
        return this.i18n.string('terms_of_use');
      case 'data-protection':
        return this.i18n.string('data_protection_declaration');
      case 'service-conditions':
        return this.i18n.string('service_conditions');
      case 'note-for-us-residents':
        return this.i18n.string('note_for_us_residents');
      default:
        return '';
    }
  }

  onNameChange(value: string) {
    this.settingsCopyForProfile.name = value;
    if (value !== this.settings.name) {
      this.changes = true;
    } else {
      this.checkForChanges();
    }
  }

  onSurnameChange(value: string) {
    this.settingsCopyForProfile.surname = value;
    if (value !== this.settings.surname) {
      this.changes = true;
    } else {
      this.checkForChanges();
    }
  }

  onDesignationChange(value: string) {
    this.settingsCopyForProfile.designation = value;
    if (this.settings && value !== this.settings.designation) {
      this.changes = true;
    } else {
      this.checkForChanges();
    }
  }

  // onPhoneNumberChange(value: string) {
  //   this.settingsCopyForProfile.phonenumber = value;
  //   if (value !== this.settings.phonenumber) {
  //     this.changes = true;
  //   } else {
  //     this.checkForChanges();
  //   }
  // }

  onEmailChange(value: string) {
    this.settingsCopyForProfile.email = value;
    if (value !== this.settings.email) {
      this.changes = true;
    } else {
      this.checkForChanges();
    }
  }

  onSubmitProfileChanges() {
    if (!this.submitDisabled()) {
      this.settings.name = this.settingsCopyForProfile.name;
      this.settings.surname = this.settingsCopyForProfile.surname;
      this.settings.designation = this.settingsCopyForProfile.designation;
      //this.settings.phonenumber = this.settingsCopyForProfile.phonenumber;
      this.settings.email = this.settingsCopyForProfile.email;
      this.changes = false;
    }
  }

  checkForChanges() {
    if (
      this.settings.name === this.settingsCopyForProfile.name &&
      this.settings.surname === this.settingsCopyForProfile.surname &&
      this.settings.designation === this.settingsCopyForProfile.designation &&
      //this.settings.phonenumber === this.settingsCopyForProfile.phonenumber &&
      this.settings.email === this.settingsCopyForProfile.email
    ) {
      this.changes = false;
    } else {
      this.changes = true;
    }
  }

  submitDisabled() {
    if (
      !this.changes ||
      this.settingsCopyForProfile.name === '' ||
      this.settingsCopyForProfile.surname === '' ||
      this.settingsCopyForProfile.email === ''
    ) {
      return true;
    } else {
      return false;
    }
  }

  toSubpage(url) {
    this.router.navigate(['settings/' + url]);
  }

  toTermsOfService() {
    if (this.i18n.Lng === 'de') {
      window.open(
        'https://www.henkel-adhesives.com/de/de/about/terms-of-use.html',
        '_blank'
      );
    } else {
      window.open(
        'https://www.henkel-adhesives.com/de/en/about/terms-of-use.html',
        '_blank'
      );
    }
  }

  toAboutPage() {
    if (this.i18n.Lng === 'de') {
      window.open(
        // todo: still no german page available
        'https://www.henkel-adhesives.com/uk/en/industries/industrial-maintenance-repair/mro-loctite-pulse.html',
        '_blank'
      );
    } else {
      window.open(
        'https://www.henkel-adhesives.com/uk/en/industries/industrial-maintenance-repair/mro-loctite-pulse.html',
        '_blank'
      );
    }
  }

  toPrivacyPolicy() {
    // No link yet provided, will probably be a custom text on the subpage
  }

  toImprint() {
    if (this.i18n.Lng === 'de') {
      window.open(
        'https://www.henkel-adhesives.com/de/de/about/imprint.html',
        '_blank'
      );
    } else {
      window.open(
        'https://www.henkel-adhesives.com/de/en/about/imprint.html',
        '_blank'
      );
    }
  }
}
