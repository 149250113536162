<div
  [class.toggle-off]="!value"
  class="ga-toggle email"
  (click)="toggleValue()"
  *ngIf="!loading && !!settings"
>
  <label class="body-bold" *ngIf="!allNotifications">{{
    i18n.string("allow_email")
  }}</label>
  <label class="body-bold" *ngIf="allNotifications">{{
    i18n.string("allow_email_notifications")
  }}</label>
  <nz-switch [ngModel]="value"> </nz-switch>
</div>
<span class="email-info-1">{{ i18n.string("email_will_be_sent_to") }}</span>
<span class="email-info-2">{{ userEmail }}</span>
