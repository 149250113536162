import { Component, Input, OnInit } from '@angular/core';

import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  @Input() error: boolean = false;

  constructor(public i18n: I18nService) {}

  ngOnInit(): void {}
}
