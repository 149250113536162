<div class="all-notification-container">
  <app-email-toggle
    [settings]="settings"
    [loading]="loading"
    [userEmail]="UserEmail"
    [(value)]="WarningNotificationsEmail"
    [allNotifications]="true"
  ></app-email-toggle>

  <app-push-notification-toggle
    [settings]="settings"
    [loading]="loading"
    [(value)]="WarningNotificationsPush"
    [allNotifications]="true"
  ></app-push-notification-toggle>
</div>
