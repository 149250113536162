<div class="sidebar-title">
  <i
    nz-icon
    [nzType]="'icons:' + titleicon"
    class="sensor-icon svg-icon sidebar-navigation-icon"
  ></i>
  <h2>{{ title }}</h2>
</div>

<ng-content></ng-content>

<app-search-filter
  [sidebar]="true"
  [onlysearch]="!showFilter"
  [notificationsFilter]="notificationsFilter"
  [plantsFilter]="plantsFilter"
  [plantFilterStatus]="plantFilterStatus"
>
</app-search-filter>
<div *ngIf="showList" style="padding-left: 8px">
  <app-spinner *ngIf="Loading" [black]="true"></app-spinner>
  <ng-container *ngIf="!Loading">
    <ng-container *ngFor="let building of Buildings">
      <span
        class="area-subtitle building-subtitle"
        [class.first-building-subtitle]="building === Buildings[0]"
        >{{ i18n.parse(building.layerName) }}</span
      >
      <span
        class="area-title"
        [class.clickable]="isLocationClickable(building)"
        (click)="navToLocation(building)"
        >{{ i18n.parse(building.objectName) }} ({{
          building.Assets.length
        }})</span
      >
      <div
        class="area-wrapper detail-bold"
        *ngFor="
          let equipment of sortAssociatedEquipment(
            building.AssociatedEquipments
          )
        "
      >
        <ng-container *ngIf="equipment.curLayer !== 'd'">
          <span class="area-subtitle">{{
            i18n.parse(equipment.layerName)
          }}</span>
          <span
            class="assoc-eq-title"
            [class.clickable]="isLocationClickable(equipment)"
            (click)="navToLocation(equipment)"
            >{{ i18n.parse(equipment.objectName) }}</span
          >
        </ng-container>
        <div class="area-inner-wrapper">
          <ng-container *ngFor="let eqasset of equipment.children">
            <div
              class="area-asset"
              *ngIf="!eqasset.isArchived || eqasset.objectID == asset.objectID"
              [class.disconnected]="!eqasset.isConnected"
              [class.activeAsset]="eqasset.objectID == asset.objectID"
              [class.activeCritAsset]="
                eqasset.objectID == asset.objectID && eqasset.critical
              "
              [class.activeWarningAsset]="
                eqasset.objectID == asset.objectID && eqasset.warning
              "
              [class.inactiveAsset]="
                eqasset.objectID == asset.objectID && eqasset.inactive
              "
              [routerLink]="['/asset', eqasset.objectID, -1]"
              (click)="eqasset.isConnected || onClick()"
            >
              <div class="area-inner-asset-wrapper">
                <span class="asset-border"></span>
                <div class="area-inner-inner-asset-wrapper">
                  <i
                    nz-icon
                    *ngIf="
                      eqasset.isConnected &&
                      !eqasset.critical &&
                      !eqasset.warning &&
                      !eqasset.inactive
                    "
                    nzType="icons:circle"
                    class="sensor-icon svg-icon mini-icon fillgreen circle-icon"
                  ></i>
                  <i
                    nz-icon
                    *ngIf="!eqasset.isConnected"
                    nzType="icons:disconnected"
                    class="sensor-icon svg-icon mini-icon fillgrey"
                  ></i>
                  <i
                    nz-icon
                    *ngIf="
                      eqasset.isConnected &&
                      eqasset.warning &&
                      !eqasset.inactive
                    "
                    nzType="icons:status-exclamation-octagon"
                    class="sensor-icon svg-icon mini-icon fillorange"
                  ></i>
                  <i
                    nz-icon
                    *ngIf="
                      eqasset.isConnected &&
                      eqasset.critical &&
                      !eqasset.inactive
                    "
                    nzType="icons:status-exclamation-triangle"
                    class="sensor-icon svg-icon mini-icon fillred"
                  ></i>
                  <i
                    nz-icon
                    *ngIf="eqasset.isConnected && eqasset.inactive"
                    nzType="icons:cross-circle"
                    class="sensor-icon svg-icon mini-icon fillblackcoral"
                  ></i>
                  <span
                    class="assetname"
                    [class.grey-text]="!eqasset.isConnected || eqasset.inactive"
                    [class.red-text]="
                      !!eqasset.isConnected &&
                      eqasset.critical &&
                      !eqasset.inactive
                    "
                    [class.orange-text]="
                      !!eqasset.isConnected &&
                      eqasset.warning &&
                      !eqasset.inactive
                    "
                   nzTooltipTitle={{eqasset.objectName}} nzTooltipPlacement="bottomLeft"  nzTooltipColor= "#5F6973"  nz-tooltip
                    >{{ i18n.parse(eqasset.objectName) }}</span
                  >
                  </div>
              </div>
            </div>
            <div
              *ngIf="eqasset.objectID == asset.objectID"
              class="area-asset-telemetry"
            >
              <ng-container *ngFor="let sensor of asset.sensors">
                <div
                  class="telemetry-inner-wrapper"
                  [class.activeTelemetry]="sensorID === sensor.sensorID"
                  [class.activeCritTelemetry]="
                    sensorID === sensor.sensorID && sensor.Critical
                  "
                  [class.activeInactiTelemetry]="
                    sensorID === sensor.sensorID && sensor.Inactive
                  "
                  [class.activeWarningTelemetry]="
                    sensorID === sensor.sensorID && sensor.Warning
                  "
                  (click)="toSensor(eqasset.objectID, sensor.sensorID)"
                >
                  <span class="telemetry-border"></span>
                  <span
                    class="telemetry-name"
                    [class.grey-text]="sensor.Inactive"
                    [class.orange-text]="sensor.Warning"
                    [class.red-text]="sensor.Critical"
                    >{{ sensor.sensorDisplayLabel }}</span
                  >
                </div>
              </ng-container>
              <ng-container *ngIf="asset.temperature">
                <ng-container *ngFor="let eqtelemetry of [asset.temperature]">
                  <div
                    class="telemetry-inner-wrapper"
                    [class.activeTelemetry]="
                      !!telemetry &&
                      telemetry.sensorID == eqtelemetry.sensorID &&
                      telemetry.measurementTypeID ==
                        eqtelemetry.measurementTypeID
                    "
                    [class.activeCritTelemetry]="
                      !!telemetry &&
                      telemetry.sensorID == eqtelemetry.sensorID &&
                      telemetry.measurementTypeID ==
                        eqtelemetry.measurementTypeID &&
                      eqtelemetry.critical
                    "
                    [class.activeWarningTelemetry]="
                      !!telemetry &&
                      telemetry.sensorID == eqtelemetry.sensorID &&
                      telemetry.measurementTypeID ==
                        eqtelemetry.measurementTypeID &&
                      eqtelemetry.warning
                    "
                    [class.activeInactiTelemetry]="
                      !!telemetry &&
                      telemetry.sensorID == eqtelemetry.sensorID &&
                      telemetry.measurementTypeID ==
                        eqtelemetry.measurementTypeID &&
                      eqtelemetry.inactive
                    "
                    (click)="
                      toSensorValue(
                        eqasset.objectID,
                        eqtelemetry.sensor.sensorID,
                        eqtelemetry.measurementTypeID
                      )
                    "
                  >
                    <span class="telemetry-border"></span>
                    <span
                      class="telemetry-name"
                      [class.red-text]="eqtelemetry.critical"
                      [class.grey-text]="eqtelemetry.inactive"
                      [class.orange-text]="eqtelemetry.warning"
                      >{{ i18n.string("temperature_of_the_edge_device") }}</span
                    >
                  </div>
                </ng-container>
              </ng-container>
              <div
                *ngIf="asset && asset.health && asset.health.length > 0"
                class="telemetry-inner-wrapper"
                [class.activeTelemetry]="sensorHealthPage"
                [class.activeCritTelemetry]="
                  sensorHealthPage && isSensorHealthCrit(asset)
                "
                [class.activeWarningTelemetry]="
                  sensorHealthPage && isSensorHealthWarning(asset)
                "
                [class.activeInactiTelemetry]="
                  sensorHealthPage && isSensorHealthInacti(asset)
                "
                (click)="toSensorHealth(eqasset.objectID)"
              >
                <span class="telemetry-border"></span>
                <span
                  class="telemetry-name"
                  [class.red-text]="isSensorHealthCrit(asset)"
                  [class.grey-text]="isSensorHealthInacti(asset)"
                  >{{ i18n.string("edge_device_health") }}</span
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
