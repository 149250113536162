<div
  [class.toggle-off]="!_value"
  class="ga-toggle"
  (click)="toggleValue()"
  *ngIf="!loading && !!settings"
>
  <label *ngIf="!allNotifications" class="body-bold">{{
    i18n.string("allow_push_notification")
  }}</label>
  <label *ngIf="allNotifications" class="body-bold">{{
    i18n.string("allow_push_notifications")
  }}</label>
  <nz-switch [ngModel]="_value"> </nz-switch>
</div>
