<app-header
  [transparent]="false"
  [title]="!!telemetry ? i18n.parse(telemetry.title) : ''"
></app-header>
<div class="spinner-container" [class.desktop]="isDesktop" *ngIf="!!loading">
  <app-spinner [black]="true"></app-spinner>
</div>

<div class="content" [class.desktop]="isDesktop">
  <div *ngIf="isDesktop" class="desktop-sidebar white-sidebar">
    <app-sidebar
      [showList]="true"
      [Asset]="asset4Sidebar"
      [loading]="loading"
      [sensorHealthPage]="IsSensorHealth"
      [telemetry]="telemetry"
    ></app-sidebar>
  </div>
  <div
    [class.desktop-main]="isDesktop"
    [class.white-sidebar-main]="isDesktop"
    *ngIf="!!asset && !loading"
  >
    <app-breadcrumbs
      [array]="Breadcrumbs"
      [isAsset]="true"
      *ngIf="!isDesktop && !!asset && asset.breadcrumbs && !breadcrumbsLoading"
      class="detail-bold"
    >
    </app-breadcrumbs>
    <div class="asset-back-btn" (click)="goBack()" *ngIf="isDesktop">
      <i nz-icon nzType="icons:arrow-left" class="svg-icon fillwhite"></i>
      <span>{{
        i18n.string("back_to") +
          (IsSensorHealth
            ? i18n.string("edge_device_health")
            : i18n.parse(this.asset.objectName))
      }}</span>
    </div>
    <div *ngIf="documentationAdded" class="ga-documentation-note body-bold">
      <i nz-icon nzType="icons:done" class="svg-icon"></i>
      <span>{{ i18n.string("add_documentation_success") }}</span>
    </div>

    <div
      *ngIf="asset.notifications.length && !isOfEdgeDevice"
      class="crit-alert"
    >
      <ng-container *ngFor="let notification of asset.notifications">
        <app-notification-alert
          *ngIf="
            sensor.measurementID == notification.measurementTypeID &&
            sensor.sensorID == notification.sensorID
          "
          [notificationList]="false"
          [notification]="notification"
        ></app-notification-alert>
      </ng-container>
    </div>
    <div *ngIf="telemetry" [class.isOfEdgeDevice]="isOfEdgeDevice">
      <div class="sensor-header flat-sensor">
        <i
          nz-icon
          [nzType]="'icons:' + telemetry.svg"
          class="sensor-icon svg-icon"
        ></i>
        <div class="sensor-header-inner-div">
          <h2 *ngIf="!isOfEdgeDevice">{{ i18n.parse(Title) }}</h2>
          <h2 *ngIf="isOfEdgeDevice">
            {{ i18n.parse(Title) }} {{ i18n.string("of_the_edge_device") }}
          </h2>
          <span *ngIf="!isOfEdgeDevice" class="detail lighter-label">{{
            sensor.sensorDisplayLabel
          }}</span>
        </div>
      </div>
      <div class="error-card" *ngIf="DisplayErrorCard && telemetry.critical">
        <span class="error-card-title">{{
          i18n.string("problem_detected_title")
        }}</span>
        <div
          class="error-card-content"
          [class.hasEdgeDeviceButton]="ErrorCardHasReplaceButton"
        >
          <i
            nz-icon
            nzType="icons:status-exclamation-triangle"
            class="svg-icon fillwhite"
          ></i>
          <span class="error-card-text">
            {{ ErrorCardText }}
          </span>
        </div>
        <app-nav-button
          [href]="InstallationAgentURL"
          [primary]="true"
          svg_icon="icons:edge_device"
          *ngIf="ErrorCardHasReplaceButton"
        >
          {{ i18n.string("replace_edge_device_button") }}</app-nav-button
        >
      </div>
      <div
        class="error-card warning"
        *ngIf="DisplayErrorCard && telemetry.warning"
      >
        <span class="error-card-title warning">{{
          i18n.string("problem_detected_title")
        }}</span>
        <div
          class="error-card-content"
          [class.hasEdgeDeviceButton]="ErrorCardHasReplaceButton"
        >
          <i
            nz-icon
            nzType="icons:status-exclamation-octagon"
            class="svg-icon mini-svg-icon left-icon"
          ></i>
          <span class="error-card-text warning">
            {{ ErrorCardText }}
          </span>
        </div>
        <app-nav-button
          [href]="InstallationAgentURL"
          [primary]="true"
          svg_icon="icons:edge_device"
          *ngIf="ErrorCardHasReplaceButton"
        >
          {{ i18n.string("replace_edge_device_button") }}</app-nav-button
        >
      </div>
      <div class="sensordata-wrapper">
        <app-sensor-value
          [telemetry]="telemetry"
          [asHeader]="true"
        ></app-sensor-value>
        <div class="grapharea-wrapper">
          <app-tab-bar
            [opaque]="true"
            [icon]="false"
            (tabChanged)="onTelemetryTimeframeSelect($event)"
            [disabled]="
              noDataTelemetry() || graphBrushActive || Timeframe.custom
            "
          >
            <app-tab-bar-item
              class="one-of-three"
              [inactive]="isTelemetryTimeframeCustom()"
              >{{ i18n.string("days") }}
            </app-tab-bar-item>
            <app-tab-bar-item
              class="one-of-three"
              [inactive]="isTelemetryTimeframeCustom()"
              >{{ i18n.string("weeks") }}
            </app-tab-bar-item>
            <app-tab-bar-item
              class="one-of-three"
              [inactive]="isTelemetryTimeframeCustom()"
              >{{ i18n.string("months") }}
            </app-tab-bar-item>
          </app-tab-bar>
          <div *ngFor="let data of VisibleTelemetryGraphs">
            <div
              class="range-picker-backdrop"
              *ngIf="data.openRangePicker > 0"
              (click)="cancelRangePick(data)"
            ></div>
            <div class="range-picker-wrapper" [class.disabled]="noData(data)">
              <ng-container *ngIf="isDesktop; else mobileDatePicker">
                <nz-range-picker
                  [nzShowToday]="false"
                  [nzAllowClear]="false"
                  [nzPlaceHolder]= "[i18n.string('start'),i18n.string('end')]"
                  [nzDisabledDate]="rangePickerDisabledDate(data, 1)"
                  #datePick
                  [(ngModel)]="range"
                  [id]="'picker_start_' + data.metadata.measurementTypeID"
                  (ngModelChange)="onRangePickerSelect($event, data)"
                >
                </nz-range-picker>
              </ng-container>
              <ng-template #mobileDatePicker>
                <nz-date-picker
                  [nzShowToday]="false"
                  [nzAllowClear]="false"
                  #datePick
                  [id]="'picker_start_' + data.metadata.measurementTypeID"
                  [nzDisabledDate]="rangePickerDisabledDate(data, 1)"
                  [(ngModel)]="range[0]"
                  (ngModelChange)="
                    onRangeStartPick(
                      data.metadata.measurementTypeID,
                      sensor.sensorID,
                      $event,
                      'picker_end_' + data.metadata.measurementTypeID,
                      data
                    )
                  "
                >
                </nz-date-picker>
                <nz-date-picker
                  [nzShowToday]="false"
                  [nzAllowClear]="false"
                  #datePick
                  [id]="'picker_end_' + data.metadata.measurementTypeID"
                  [nzDisabledDate]="rangePickerDisabledDate(data, 2)"
                  [(ngModel)]="range[1]"
                  (ngModelChange)="
                    onRangeEndPick(
                      data.metadata.measurementTypeID,
                      sensor.sensorID,
                      $event,
                      data
                    )
                  "
                >
                </nz-date-picker>
                <button>
                  <span
                    [class.active]="data.openRangePicker === 1"
                    (click)="
                      openRangePicker(
                        'picker_start_' + data.metadata.measurementTypeID,
                        data
                      )
                    "
                    >{{ range[0] | date }}</span
                  >
                  -
                  <span
                    [class.active]="data.openRangePicker === 2"
                    (click)="
                      openRangePicker(
                        'picker_end_' + data.metadata.measurementTypeID,
                        data
                      )
                    "
                    >{{ range[1] | date }}</span
                  >
                  <i
                    nz-icon
                    nzType="icons:edit"
                    class="sensor-icon svg-icon"
                    (click)="
                      openRangePicker(
                        'picker_start_' + data.metadata.measurementTypeID,
                        data
                      )
                    "
                    [class.fillgrey]="noData(data)"
                  ></i>
                </button>
              </ng-template>
            </div>
            <div class="graph-wrapper">
              <app-spinner *ngIf="!!data.loading" [black]="true"></app-spinner>
              <app-graph
                [Data]="data"
                *ngIf="!error"
                [class.hidden]="!!data.loading"
                [Timeframe]="timeframe"
                [Settings]="settings"
                Type="line"
                (brushStateChange)="graphBrushActive = $event"
                (timeframeUpdated)="onDateRangeUpdatedfromGraph($event)"
                (timeframeReset)="resetTimeframeSelect()"
              ></app-graph>
              <span *ngIf="!data.loading && !!error" class="error">{{
                i18n.string("error_loading_data")
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="sensor && !isOfEdgeDevice && false" class="history-wrapper">
      <div class="sensor-header flat-sensor">
        <i nz-icon nzType="icons:history" class="sensor-icon svg-icon"></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.string("history") }}</h2>
          <span class="detail lighter-label">{{
            sensor.sensorDisplayLabel
          }}</span>
        </div>
      </div>
      <div class="history">
        <nz-list nzBordered [nzFooter]="historyfooter" class="history-list">
          <nz-list-item *ngFor="let item of historyItems.slice(0, 10)">
            <app-history-item
              [dateFormat]="settings.DateFormat"
              [detailedhistory]="true"
              [item]="item"
              [asset]="asset"
            >
            </app-history-item>
          </nz-list-item>
        </nz-list>

        <ng-template #historyfooter>
          <div class="history-footer-item">
            <app-nav-button
              [primary]="true"
              svg_icon="icons:eye"
              [disabled]="!historyItems || historyItems.length === 0"
              (click)="
                toSensorHistory(sensor.sensorID, telemetry.measurementTypeID)
              "
            >
              {{ i18n.string("show_all_entries") }}</app-nav-button
            >
          </div>
          <div class="history-footer-item">
            <app-nav-button
              [darksecondary]="true"
              svg_icon="icons:documentation"
              (click)="overlayDocumentation = true"
            >
              {{ i18n.string("add_documentation") }}</app-nav-button
            >
            <app-docu-wizard
              *ngIf="overlayDocumentation"
              (saved)="onDocumentationAdded($event)"
              [assetId]="assetID"
              [sensorId]="sensorID"
              (close)="overlayDocumentation = false"
            ></app-docu-wizard>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="IsSensorHealth" class="location-wrapper">
      <div class="sensor-header">
        <i nz-icon nzType="icons:location" class="sensor-icon svg-icon"></i>
        <h2>{{ i18n.string("location") }}</h2>
      </div>
      <div class="location-spinner-container" *ngIf="breadcrumbsLoading">
        <app-spinner [black]="true"></app-spinner>
      </div>
      <div class="location-content">
        <div class="location-spinner-container" *ngIf="breadcrumbsLoading">
          <app-spinner [black]="true"></app-spinner>
        </div>

        <nz-descriptions
          *ngIf="asset.breadcrumbs && !breadcrumbsLoading"
          [nzColumn]="1"
          nzBordered
          [nzSize]="'small'"
          class="nzNotATable"
        >
          <ng-container
            *ngFor="let breadcrumb of asset.breadcrumbs; let i = index"
          >
            <nz-descriptions-item
              *ngIf="i > 0"
              [nzTitle]="i18n.parse(breadcrumb.layerName)"
            >
              <span
                [class.clickable]="isBreadcrumbClickable(breadcrumb)"
                (click)="navToBreadcrumb(breadcrumb)"
                >{{ i18n.parse(breadcrumb.objectName) }}
              </span>
            </nz-descriptions-item>
          </ng-container>
        </nz-descriptions>
      </div>
    </div>
  </div>
</div>
