import { LocationStrategy } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { forkJoin, fromEvent, Observable, Subscription, timer } from 'rxjs';

import { AuthenticationService } from '../cc-framework/services/authentication.service';
import { StorageService } from '../cc-framework/services/storage.service';
import { environment } from '../environments/environment';
import { slideInAnimation } from './animations';
import { AssetsService } from './services/assets.service';
import { BannerNotificationService } from './services/banner-notification.service';
import { I18nService } from './services/i18n.service';
import { NotificationService } from './services/notification.service';
import { SettingsService } from './services/settings.service';
import { UiService } from './services/ui.service';
import { UtilService } from './services/util.service';

declare const window: any;

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, AfterViewInit {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatus: string = 'online';
  modalOpen: boolean = false;
  Internet: string = 'No internet connection';
  public showBannerMaintenance: boolean = false;
  REFRESH_INTERVAL: number = 1000 * 60 * 1; // 1 minute
  loading: boolean;
  loadingError: boolean;
  @ViewChild('scrollContent')
  private scrollContentDiv!: ElementRef<HTMLElement>;

  get isAuth(): boolean {
    return this.auth.isLoggedIn();
  }

  // get isMobile(): boolean {
  //   return this.ui.IsMobile;
  // }
  // get isTablet(): boolean {
  //  return this.ui.IsTablet;
  // }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  get environmentInstanceName(): string {
    return environment.environmentInstanceName;
  }

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private storage: StorageService,
    public ui: UiService,
    private assets: AssetsService,
    private settings: SettingsService,
    public util: UtilService,
    public notifications: NotificationService,
    private location: LocationStrategy,
    private msalAuth: MsalService,
    private bannerNotificationService: BannerNotificationService,
    public i18n: I18nService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        if (window.adobeDataLayer[0]) {
          this.isCallingObj(window.adobeDataLayer[0]);
          window.adobeDataLayer[0].web.webPageDetails['URL'] =
            window.location.protocol + window.location.host + this.router.url;
          window.adobeDataLayer[0].web.webReferrer['URL'] =
            window.location.protocol + window.location.host + this.router.url;
          if (this.storage.UserName) {
            window.adobeDataLayer[0]
              ? (window.adobeDataLayer[0]._henkel.user['id'] =
                  this.storage.UserName)
              : undefined;
          }
        }
      }
    });
    this.callingScript();
    document.cookie = 'env=' + environment.environmentInstanceName;
  }

  callingScript() {
    const scriptCookie = document.createElement('script');
    scriptCookie.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    scriptCookie.setAttribute('data-document-language', 'true');
    scriptCookie.setAttribute('type', 'text/javascript');
    scriptCookie.setAttribute('charset', 'UTF-8');
    if (environment.environmentInstanceName == 'PROD') {
      scriptCookie.setAttribute(
        'data-domain-script',
        'ae27a7fc-9246-4aee-93db-8bf4beb8e2b2'
      );
    } else if (environment.environmentInstanceName == 'TEST') {
      scriptCookie.setAttribute(
        'data-domain-script',
        '2d6f8900-6dc1-4195-95e7-c26f923c1bda-test'
      );
    } else {
      scriptCookie.setAttribute(
        'data-domain-script',
        '0c30f3b6-12ac-4d95-8d4d-98fc897c7492-test'
      );
    }
    document.head.appendChild(scriptCookie);
  }

  isCallingObj(obj) {
    window.adobeDataLayer.pop();
    window.adobeDataLayer.push(obj);
  }

  ngOnInit() {
    // * Get the online/offline status from browser window

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        this.connectionStatus = 'online';
        this.modalOpen = false;
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.connectionStatus = 'offline';
        this.modalOpen = true;
      })
    );

    this.msalAuth.instance
      .handleRedirectPromise()
      .then((result) => {
        // if (!!result && !!result.idToken) this.util.setLoadingStarted();
        // console.warn('!!! handleRedirectPromise result', result);
        // this.authorizeMsalLogin(result.idToken);
      })
      .catch((err) => {
        // TODO: Handle errors
        // console.error(err);
      });
    this.util.isLoading().subscribe((state) => (this.loading = state)); // get loading state for splash screen
    this.util.loadingFailed().subscribe((state) => (this.loadingError = state));
    if (this.storage.Token) {
      // if already logged in, show splash screen and preload plants and user settings
      this.util.setLoadingStarted();
      forkJoin([
        this.assets.getRegions(),
        // Not needed. The tab bar is always visible and fetches current notifications, 2023-02-06, LKlum
        // this.assets.getNotifications(),
        this.settings.getUserSettings(),
        timer(1500),
      ]).subscribe(
        () => {
          this.util.setLoadingFinished();
        },
        (e) => {
          if (e.status === 401) {
            this.util.setLoadingFinished();
            return;
          }
          console.error(e);
          console.error(
            'If you see this, please forward the above error to florian.bannier@cosmoconsult.com. Thanks! :)'
          );
          if (environment.debugMode) alert('please check log console');
          this.util.setLoadingFinished(true);
        }
      );
    } else {
      window.adobeDataLayer[0]
        ? (window.adobeDataLayer[0].userAccount['loginStatus'] = 'logged out')
        : undefined;
      // if not logged in, splash screen will be shown after login
      this.util.setLoadingFinished();
    }
    window.adobeDataLayer[0]
      ? (window.adobeDataLayer[0].environment['type'] =
          environment.environmentInstanceName.toLowerCase())
      : undefined;
    //banner-maintenance
    this.showBannerDowntime();
    setInterval(() => {
      this.showBannerDowntime();
    }, this.REFRESH_INTERVAL);
  }

  showBannerDowntime() {
    this.bannerNotificationService.getMaintenanceMode().subscribe((res) => {
      // if webservice goes down, res will be undefined. Show banner in this case
      // if (!res || res['maintenanceMode'] === true) {
      if (res && res['maintenanceMode'] === true) {
        this.showBannerMaintenance = true;
      } else {
        this.showBannerMaintenance = false;
      }
    });
  }

  ngAfterViewInit() {
    if (this.auth.isLoggedIn()) this.notifications.subscribeToNotifications();
  }

  cancelClick() {
    this.modalOpen = false;
  }
  tryReconnect() {
    // check the connection status, if online remove the banner
    if (this.connectionStatus === 'online') {
      this.modalOpen = false;
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }

  headerTransparent(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.transparentHeader
    );
  }

  onActivate(outletdata) {
    window.adobeDataLayer[0]
      ? (window.adobeDataLayer[0]._henkel.page['group'] =
          outletdata.activatedRouteData.animation)
      : undefined;
    window.adobeDataLayer[0]
      ? (window.adobeDataLayer[0].web.webPageDetails['name'] =
          outletdata.activatedRouteData.animation)
      : undefined;
    window.adobeDataLayer[0]
      ? (window.adobeDataLayer[0].web.webPageDetails['isHomePage'] =
          outletdata.activatedRouteData.animation === 'Homepage' ? true : false)
      : undefined;
    if (this.scrollContentDiv) {
      (this.scrollContentDiv.nativeElement as HTMLElement).scrollTop = 0;
    }
  }
}
