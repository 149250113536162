<app-splash-screen *ngIf="loading || loadingError" [error]="loadingError"></app-splash-screen>
<ng-container [class.remove]="loading || loadingError">
  <span *ngIf="environmentInstanceName !== 'PROD'" class="test">{{environmentInstanceName}}</span>
  <app-banner-maintenance [maintenance]="showBannerMaintenance"></app-banner-maintenance>
  <app-desktop-header *ngIf="isAuth && isDesktop"></app-desktop-header>
  <div class="scrollable" [class.desktop]="isDesktop" #scrollContent>
    <!--<app-header [transparent]="headerTransparent(outlet)" ></app-header>-->
    <div id="main" [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet" (activate)="onActivate(outlet)"></router-outlet>
    </div>
  </div>
  <app-footer *ngIf="isAuth && !isDesktop"></app-footer>
</ng-container>

<div class="modal-content">
  <nz-modal
    nzClassName="offline-status-modal"
    [(nzVisible)]="modalOpen"
    [nzFooter]="null"
    [nzCentered]="true"
    [nzTitle]="Internet"
    (nzOnCancel)="cancelClick()"
  >
    <div class="network-modal-body">
      <div class="network-modal-icon set-margin-auto">
        <i
          style="font-size: 40px"
          nz-icon
          nzType="icons:status-exclamation-triangle"
        ></i>
      </div>
      <div class="set-margin-auto">
        <h3 class="network-modal-heading">
          {{ i18n.string("network_issue_msg") }}
        </h3>
      </div>
      <div class="network-modal-content set-margin-auto">
        {{ i18n.string("network_issue_content") }}
      </div>
      <div class="network-modal-button asset-nav-button-content">
        <i nz-icon nzType="icons:refresh" class="svg-icon fillwhite"></i>
        <span (click)="tryReconnect()" class="reconnect">
          {{ i18n.string("network_issue_reconnect") }}</span
        >
      </div>
    </div>
  </nz-modal>
</div>
