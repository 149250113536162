<!-- asset/plant header -->
<div class="header-wrapper">
  <ng-container *ngIf="!backBtn">
    <a aria-label="Home" [routerLink]="['/home']"
      ><img id="loctite-logo" src="assets/images/LoctitePulseLogo.svg"
    /></a>
  </ng-container>
  <ng-container *ngIf="backBtn"
    ><a class="back-btn" (click)="back()" aria-label="label">
      <i nz-icon nzType="icons:arrow-left" class="svg-icon"></i>
      <span class="body-bold">{{ backTo }}</span>
    </a></ng-container
  >
  <div class="header-content">
    <span class="body-bold">{{ title }}</span>
  </div>
  <app-tab-menu [header]="true"></app-tab-menu>
    <!-- banner Notification -->
    <app-banner-notification></app-banner-notification>
</div>
