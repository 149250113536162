<!-- regular header -->
<div
  class="header-wrapper"
  [class.hidden]="isDesktop"
  *ngIf="transparent"
  [class.transparent]="transparent"
>
  <a aria-label="Home" [routerLink]="['/home']"
    ><img id="loctite-logo" src="assets/images/LoctitePulseLogo.svg"
  /></a>
  <button
    class="ga-icon-button floating-btn"
    *ngIf="!IsOnSettingsPage"
    aria-label="settings"
    (click)="toSettings()"
  >
    <i nz-icon nzType="icons:more-information" class="noMarginRight"></i>
  </button>
  <!-- banner Notification -->
  <app-banner-notification></app-banner-notification>
</div>

<!-- asset/plant header -->
<div
  class="header-wrapper"
  [class.hidden]="isDesktop"
  *ngIf="!transparent"
  [class.opaque]="!transparent"
  [class.overlap]="overlap"
>
  <a class="back-btn" (click)="back()" aria-label="label">
    <i nz-icon nzType="icons:arrow-left" class="svg-icon"></i>
    <span class="body-bold">{{ backTo }}</span>
  </a>
  <div class="header-content">
    <span class="body-bold">{{ title }}</span>
  </div>
  <button
    class="ga-icon-button floating-btn"
    *ngIf="!IsOnSettingsPage"
    aria-label="settings"
    (click)="toSettings()"
  >
    <i nz-icon nzType="icons:more-information" class="noMarginRight"></i>
  </button>
  <!-- banner Notification -->
  <app-banner-notification></app-banner-notification>
</div>
