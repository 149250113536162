import { Component, OnInit } from '@angular/core';

import { BannerNotificationService } from '../../services/banner-notification.service';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls: ['./banner-notification.component.scss'],
})
export class BannerNotificationComponent implements OnInit {
  public showBannerNotification: boolean = false;
  public bannerNotificationType: string = null;
  public bannerNotificationTitle: string = null;
  public bannerNotificationMessage: string = null;

  constructor(
    private bannerNotificationService: BannerNotificationService,
    public i18n: I18nService
  ) {}

  ngOnInit() {
    this.bannerNotificationService.getBannerNotifications().subscribe((res) => {
      if (res.filter((r) => r.type === 'maintenance').length > 0) {
        this.showBannerNotification = true;
        this.bannerNotificationType = 'maintenance';
        this.bannerNotificationTitle = this.i18n.string('system_maintenance');
        this.bannerNotificationMessage = this.i18n.string(
          'maintenance_message'
        );
      } else if (res.filter((r) => r.type === 'outage').length > 0) {
        this.showBannerNotification = true;
        this.bannerNotificationType = 'outage';
        this.bannerNotificationTitle = this.i18n.string('system_outage');
        this.bannerNotificationMessage = this.i18n.string('outage_message');
      } else if (res.filter((r) => r.type === 'custom').length > 0) {
        this.showBannerNotification = true;
        this.bannerNotificationType = 'custom';
        this.bannerNotificationTitle = this.i18n.parse(
          res.filter((r) => r.type === 'custom')[0].title
        );
        this.bannerNotificationMessage = this.i18n.parse(
          res.filter((r) => r.type === 'custom')[0].message
        );
      }
    });
  }

  closeBannerNotification(e) {
    this.bannerNotificationService
      .dismissBannerNotification(this.bannerNotificationType)
      .subscribe(
        () => {
          this.showBannerNotification = false;
          this.bannerNotificationTitle = null;
          this.bannerNotificationTitle = null;
          this.bannerNotificationMessage = null;
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
