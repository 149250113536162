<div class="sensordata-wrapper">
  <app-sensor-value [telemetry]="MainCurrentTelemetry" [asHeader]="true">
  </app-sensor-value>
  <div class="grapharea-wrapper">
    <app-tab-bar
      [opaque]="true"
      [icon]="false"
      (tabChanged)="onTelemetryTimeframeSelect($event, CurrentTelemetry)"
      [disabled]="noDataTelemetry(CurrentTelemetry) || graphBrushActive"
    >
      <app-tab-bar-item
        class="one-of-three"
        [inactive]="isTelemetryTimeframeCustom"
      >
        {{ i18n.string("days") }}</app-tab-bar-item
      >
      <app-tab-bar-item
        class="one-of-three"
        [inactive]="isTelemetryTimeframeCustom"
      >
        {{ i18n.string("weeks") }}</app-tab-bar-item
      >
      <app-tab-bar-item
        class="one-of-three"
        [inactive]="isTelemetryTimeframeCustom"
      >
        {{ i18n.string("months") }}</app-tab-bar-item
      >
    </app-tab-bar>
    <ng-container *ngIf="!graphDisplays.length">
      <div class="graph-wrapper">
        <app-spinner [black]="true"></app-spinner>
      </div>
    </ng-container>
    <ng-container
      *ngIf="graphDisplays.length > 0 && !!graphDisplays[0].graphData"
    >
      <div
        class="range-picker-backdrop"
        *ngIf="graphDisplays[0].graphData[0].openRangePicker > 0"
        (click)="cancelRangePick(graphDisplays[0].graphData[0])"
      ></div>
      <div
        class="range-picker-wrapper"
        [class.disabled]="noDataTelemetry(CurrentTelemetry)"
      >
        <ng-container *ngIf="isDesktop; else mobileDatePicker">
          <nz-range-picker
            [nzShowToday]="false"
            [nzAllowClear]="false"
            [nzPlaceHolder]= "[i18n.string('start'),i18n.string('end')]"
            [nzDisabledDate]="
              rangePickerDisabledDate(graphDisplays[0].graphData[0], 1)
            "
            #datePick
            [(ngModel)]="range"
            [id]="
              'picker_start_' +
              graphDisplays[0].graphData[0].metadata.measurementTypeID
            "
            (ngModelChange)="
              onRangePickerSelect($event, graphDisplays[0].graphData[0])
            "
          >
          </nz-range-picker>
        </ng-container>
        <ng-template #mobileDatePicker>
          <nz-date-picker
            [nzShowToday]="false"
            [nzAllowClear]="false"
            #datePick
            [id]="
              'picker_start_' +
              graphDisplays[0].graphData[0].metadata.measurementTypeID
            "
            [nzDisabledDate]="
              rangePickerDisabledDate(graphDisplays[0].graphData[0], 1)
            "
            [(ngModel)]="range[0]"
            (ngModelChange)="
              onRangeStartPick(
                graphDisplays[0].graphData[0].metadata.measurementTypeID,
                graphDisplays[0].currentTelemetry.sensor.sensorID,
                $event,
                'picker_end_' +
                  graphDisplays[0].graphData[0].metadata.measurementTypeID,
                graphDisplays[0].graphData[0]
              )
            "
          >
          </nz-date-picker>
          <nz-date-picker
            [nzShowToday]="false"
            [nzAllowClear]="false"
            #datePick
            [id]="
              'picker_end_' +
              graphDisplays[0].graphData[0].metadata.measurementTypeID
            "
            [nzDisabledDate]="
              rangePickerDisabledDate(graphDisplays[0].graphData[0], 2)
            "
            [(ngModel)]="range[1]"
            (ngModelChange)="
              onRangeEndPick(
                graphDisplays[0].graphData[0].metadata.measurementTypeID,
                graphDisplays[0].currentTelemetry.sensor.sensorID,
                $event,
                graphDisplays[0].graphData[0]
              )
            "
          >
          </nz-date-picker>
          <button>
            <span
              [class.active]="
                graphDisplays[0].graphData[0].openRangePicker === 1
              "
              (click)="
                openRangePicker(
                  'picker_start_' +
                    graphDisplays[0].graphData[0].metadata.measurementTypeID,
                  graphDisplays[0].graphData[0]
                )
              "
              >{{ range[0] | date : DateFormat }}</span
            >
            -
            <span
              [class.active]="
                graphDisplays[0].graphData[0].openRangePicker === 2
              "
              (click)="
                openRangePicker(
                  'picker_end_' +
                    graphDisplays[0].graphData[0].metadata.measurementTypeID,
                  graphDisplays[0].graphData[0]
                )
              "
              >{{ range[1] | date : DateFormat }}</span
            >
            <i
              nz-icon
              nzType="icons:edit"
              class="sensor-icon svg-icon"
              [class.fillgrey]="noData(graphDisplays[0].graphData[0])"
              (click)="
                openRangePicker(
                  'picker_start_' +
                    graphDisplays[0].graphData[0].metadata.measurementTypeID,
                  graphDisplays[0].graphData[0]
                )
              "
            ></i>
          </button>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngFor="let display of graphDisplays">
      <ng-container *ngIf="!display.loading">
        <ng-contianer *ngFor="let data of display.graphData">
          <div class="graph-wrapper">
            <app-spinner
              *ngIf="!data || !!data.loading"
              [black]="true"
            ></app-spinner>
            <app-graph
              [Data]="data"
              *ngIf="!data.error"
              [class.hidden]="!!data.loading"
              (brushStateChange)="graphBrushActive = $event"
              [Timeframe]="getTimeframe(CurrentTelemetry)"
              [Settings]="settings"
              Type="line"
              (timeframeUpdated)="onDateRangeUpdatedfromGraph($event)"
            >
            </app-graph>
            <span *ngIf="!data.loading && !!data.error" class="error">{{
              i18n.string("error_loading_data")
            }}</span>
          </div>
        </ng-contianer>
      </ng-container>
    </ng-container>
  </div>
</div>
