<!--<app-header [transparent]="true" *ngIf="!isLoading"></app-header>-->
<div class="login-wrapper" [class.mobile]="isMobile" [class.tablet]="isTablet">
  <div class="login-image">
    <img
      nz-image
      nzDisablePreview
      nzSrc="assets/images/login_image.png"
      height="100%"
    />
  </div>

  <div class="loginForm" *ngIf="!isLoading">
    <app-banner-notification></app-banner-notification>
    <form nz-form [formGroup]="loginForm" name="loginForm">
      <img
        nz-image
        nzDisablePreview
        class="logo"
        nzSrc="assets/images/LoctitePulseLogo_red.svg"
        *ngIf="!forgotPassword"
      />
      <div *ngIf="pwChanged" class="pw-changed-note body-bold">
        <div class="note-icon-wrapper">
          <i
            nz-icon
            nzType="icons:check-circle-filled"
            class="svg-icon multicolorIcon"
          ></i>
        </div>
        <span>{{ i18n.string("pw_changed_success") }}</span>
      </div>
      <div *ngIf="signedUp" class="signed-up-note body-bold">
        <div class="note-icon-wrapper">
          <i
            nz-icon
            nzType="icons:check-circle-filled"
            class="svg-icon multicolorIcon"
          ></i>
        </div>
        <span>{{ i18n.string("signed_up_success") }}</span>
      </div>
      <div class="login-inner-wrapper" [class.visible]="!forgotPassword">
        <ng-container *ngIf="credentialLogin">
          <nz-form-item>
            <nz-form-label>{{ I18N.String("username") }}</nz-form-label>
            <nz-input-group>
              <input
                formControlName="userName"
                nz-input
                [placeholder]="I18N.String('username')"
                [(ngModel)]="user"
                (keydown.enter)="onSubmitUser()"
                oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
                name="usernameInput"
              />
            </nz-input-group>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label>{{ I18N.String("password") }}</nz-form-label>
            <nz-input-group [nzSuffix]="passwordEye">
              <input #password formControlName="password" nz-input [type]="passwordVisible ? 'text' : 'password'"
                [placeholder]="I18N.String('password')" [(ngModel)]="pw" (keydown.enter)="onSubmitPW()"
                [nzSuffix]="passwordEye" oninput="this.value = this.value.replace(/^\s+|\s+$/g, '');
                this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');" name="passwordInput" />
            </nz-input-group>
          </nz-form-item>
          <!-- <span class="error" [class.hidden]="!error">{{
            I18N.String("login_failed")
          }}</span>
          <p class="error" [class.hidden]="!acc_blocked">
            {{ I18N.String("acc_blocked") }}
          </p> -->
          <span class="error" *ngIf="error">
            {{ I18N.String("login_failed") }}</span
          >
          <p class="error" *ngIf="acc_blocked">
            {{ I18N.String("acc_blocked") }}
          </p>
          <nz-form-item>
            <nz-form-control>
              <div class="loginButtonWrapper loginButtonSlim forgot-password">
                <app-nav-button
                  [lighttertiary]="true"
                  (click)="forgotPassword = true; hasSendPWForgotMail = false"
                >
                  {{ I18N.String("forgot_password_button") }}
                </app-nav-button>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <div class="loginButtonWrapper loginButtonMarginTop">
                <app-nav-button
                  [primary]="true"
                  svg_icon="icons:login"
                  (click)="login()"
                >
                  {{ I18N.String("enter_loctite") }}
                </app-nav-button>
              </div>
            </nz-form-control>
          </nz-form-item>
          <ng-template #passwordEye>
            <i
              nz-icon
              class="passwordEye"
              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"
            ></i>
          </ng-template>
        </ng-container>
      </div>
      <div class="forgot-password-wrapper" [class.visible]="!!forgotPassword">
        <div class="loginButtonWrapper loginButtonSlim">
          <app-nav-button
            [lighttertiary]="true"
            svg_icon="icons:arrow-left"
            (click)="forgotPassword = false"
          >
            {{ I18N.String("back_to_login") }}
          </app-nav-button>
        </div>
        <ng-container *ngIf="!hasSendPWForgotMail">
          <h1>{{ I18N.string("forgot_password_title") }}</h1>
          <span>{{ I18N.string("forgot_password_text") }}</span>
          <nz-form-item>
            <nz-form-label>{{ I18N.String("email") }}</nz-form-label>
            <nz-input-group>
              <input
                formControlName="email"
                nz-input
                [placeholder]="I18N.String('email')"
                [(ngModel)]="email"
                (keydown.enter)="onSendPWForgotMail()"
                oninput="this.value = this.value.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');"
              />
            </nz-input-group>
          </nz-form-item>
          <p class="error" [class.hidden]="!unknownMailError && !sendMailError">
            <ng-container *ngIf="unknownMailError">{{
              I18N.String("unknown_mail")
            }}</ng-container>
            <ng-container *ngIf="sendMailError">{{
              I18N.String("mail_send_error")
            }}</ng-container>
          </p>
          <nz-form-control>
            <div class="loginButtonWrapper loginButtonMarginTop">
              <app-nav-button
                [disabled]="email == ''"
                [primary]="true"
                svg_icon="icons:mail"
                (click)="onSendPWForgotMail()"
              >
                {{ I18N.String("send_mail") }}
              </app-nav-button>
            </div>
          </nz-form-control>
        </ng-container>
        <ng-container *ngIf="!!hasSendPWForgotMail">
          <h1>{{ I18N.string("check_your_email") }}</h1>
          <p>
            {{ I18N.string("check_your_email_generic") }}
            <!--{{ I18N.string("check_your_email1")
            }}<span class="body-bold">"{{ email }}"</span
            >{{ I18N.string("check_your_email2") }}-->
          </p>
          <div class="specific-buttonwrapper">
            <app-nav-button
              [darksecondary]="true"
              svg_icon="icons:installation_agent"
              (click)="PWForgotMailNotReceived()"
              >{{ I18N.String("email_not_received") }}
            </app-nav-button>
          </div>
        </ng-container>
      </div>
    </form>

    <div class="signin-with-MS"  *ngIf="msalLogin && !forgotPassword">
      <app-nav-button
        [darksecondary]="true"
        [fullwidth]="true"
        (click)="loginViaMSAL()"
        >{{ i18n.string("sign_in_with_microsoft") }}
      </app-nav-button>
      <i
        nz-icon
        nzType="icons:signinMS"
        class="svg-icon multicolorIcon"
      ></i>
    </div>

    <div
      class="loginButtonWrapper loginButtonSlim installationAgentBtn signup"
      *ngIf="!forgotPassword && !disableSignup"
    >
      <app-nav-button
        [lighttertiary]="true"
        [fullwidth]="true"
        (click)="signupLogin()"
        >{{ i18n.string("sign_up") }}
      </app-nav-button>
    </div>
    <ng-container *ngIf="portalLogin && !forgotPassword">
      <div
        class="loginButtonWrapper"
        [class.loginButtonMarginTop]="!credentialLogin"
      >
        <app-nav-button
          [primary]="true"
          svg_icon="icons:login"
          (click)="remoteLogin()"
          >{{ I18N.String("via_portal") }}
        </app-nav-button>
      </div>
    </ng-container>

    <div
      *ngIf="debugMode && !forgotPassword"
      class="loginButtonWrapper"
      [class.loginButtonMarginTop]="!credentialLogin && !portalLogin"
    >
      <app-nav-button
        [primary]="true"
        svg_icon="icons:login"
        (click)="debugLogin()"
      >
        {{ I18N.String("skip_login") }}
      </app-nav-button>
    </div>

    <div
      class="loginButtonWrapper installationAgentBtn"
      *ngIf="!forgotPassword"
    >
      <app-nav-button
        [href]="InstallationAgentURL"
        [darksecondary]="true"
        svg_icon="icons:installation_agent"
      >
        {{ I18N.String("go_to_ia") }}
      </app-nav-button>
      <div *ngIf="!country" class="container">
        <button class="row">
          <div (click)="toLegalImprint()">
            {{ i18n.string("imprint2") }}
          </div>
        </button>

        <button class="row">
          <div (click)="toTermsOfUse()">
            {{ i18n.string("terms_of_use") }}
          </div>
        </button>

        <button class="row">
          <div (click)="toDataProtectionDeclaration()">
            {{ i18n.string("data_protection_declaration") }}
          </div>
        </button>

        <button class="row">
          <div (click)="toServiceConditions()">
            {{ i18n.string("service_conditions") }}
          </div>
        </button>

        <button *ngIf="i18n.Lng === 'en'" class="row">
          <div (click)="toNoteForUsResidents()">
            {{ i18n.string("note_for_us_residents") }}
          </div>
        </button>
        <button *ngIf="i18n.Lng === 'en'" class="row">
          <div (click)="toCookieConsent()">
            {{ i18n.string("cookie_consent") }}
          </div>
        </button>
        <button *ngIf="i18n.Lng === 'en'" class="row">
          <div (click)="toCookiesSettings()">
            {{ i18n.string("cookies_settings") }}
          </div>
        </button>
      </div>

      <div class="container" *ngIf="country">
        <button class="row">
          <div (click)="toTermsOfUse()">
            {{ i18n.string("terms_of_use") }}
          </div>
        </button>
        <button class="row">
          <div (click)="toprivacyPolicy()">
            {{ i18n.string("privacy_policy") }}
          </div>
        </button>
        <!-- <button class="row">
          <div (click)="toCookies()">
            {{ i18n.string("cookies") }}
          </div>
        </button> -->
        <button class="row">
          <div (click)="toCAPrivacyRights()">
            {{ i18n.string("CAPrivacyRights") }}
          </div>
        </button>
        <button class="row">
          <div (click)="toDSAR()">
            {{ i18n.string("DSAR") }}
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
